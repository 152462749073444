/* Colors Neuhaus */
/* Fonts Neuhaus */
html,
body,
.page {
  color: #503223;
  background-color: #fff;
  line-height: 1.3em;
  min-height: 100vh;
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

body {
  font-size: 1rem; }

.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

div[role="main"] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

/* stylelint-disable-next-line */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .browserModal {
    display: block !important;
    top: inherit; }
    .browserModal .modal-text {
      max-width: 450px; }
  .modal-background {
    display: block;
    z-index: 500;
    top: 0; }
  body {
    overflow: hidden; } }

a.link {
  color: #503223;
  text-decoration: underline;
  font-weight: 700; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.h1,
h1 {
  font-size: calc(1.425rem + 2.1vw); }
  @media (min-width: 1200px) {
    .h1,
    h1 {
      font-size: 3rem; } }

.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    .h2,
    h2 {
      font-size: 2rem; } }

h3,
.h3 {
  font-size: 1.25rem; }

h4,
.h4 {
  font-size: 1.125rem; }

h5,
.h5 {
  font-size: 1rem; }

h6,
.h6 {
  font-size: 0.875rem; }

.font-link, .font-caption, .font-title, .font-heading {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.font-copy {
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

a,
a:hover,
a:active,
a:focus {
  color: #503223; }

div.sticky-bottom {
  position: sticky;
  bottom: 0;
  text-align: center;
  background-color: #ffff;
  display: inline-block;
  z-index: 1;
  border-top: #ddd 1px solid;
  margin: 0;
  width: 100%;
  height: 62px; }

.stickyFooterName {
  margin-bottom: 2px;
  font-size: 16px;
  margin-top: 10px; }

p.SFPDescription {
  font-size: 14px;
  line-height: 14px; }

div.sticky-content {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: left;
  height: 61px; }

div.message-content-pages {
  height: 60px; }

.noDecor:hover {
  text-decoration: none; }

.stickyClosebutton {
  border: 0;
  background-color: transparent;
  color: #503223;
  cursor: pointer; }

.stickyClosebutton.close-icon {
  margin-top: 2px; }

.stickyClosebutton:focus {
  outline: none; }

.SFPName {
  font-family: flama, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #503223;
  font-size: 0.875rem;
  font-weight: 700; }

.SFPImage {
  width: 57px;
  height: 57px; }

@media only screen and (max-width: 600px) {
  div.sticky-bottom {
    height: 67px; }
  .stickyClosebutton {
    color: #503223;
    right: 0; }
  .closeButton {
    top: -40px;
    position: absolute;
    background-color: transparent;
    padding-left: 4px; }
  .lastCol {
    margin-left: 25px; }
  .SFPImage {
    width: 57px;
    height: 57px;
    margin-right: 22px;
    margin-left: 20px;
    position: relative;
    bottom: -2px; }
  .SFPLink {
    padding-left: 12px;
    padding-right: 12px; }
  div.message-content-pages {
    height: 45px; } }

.storepage {
  width: 100%;
  overflow: hidden; }
